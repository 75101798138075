export const SENSORS_LOADING_MESSAGE="Estamos consultando la información de tus sensores";
export const ERROR_401_MESSAGE="Ups, parece que te has estado inactivo por mucho tiempo. Ingresa nuevamente ";
export const GRAPHS_LOADING_MESSAGE="Estamos cargando la información de tus sensores";

export const NOT_ALLOWED_ORIENTATION = {
    PORTRAIT_PRIMARY: "portrait-primary",
    PORTRAIT_SECONDARY: "portrait-secondary",
};

export const operators = [
    {
        label: "Claro",
        value: "claro"
    },
    {
        label: "Movistar",
        value: "movistar"
    },
    {
        label: "ETB",
        value: "etb"
    },
    {
        label: "Tigo",
        value: "tigo"
    },
    {
        label: "Avantel",
        value: "avantel"
    },
    {
        label: "Virgin",
        value: "virgin"
    }

]

export const VARIABLES = [
    { id: "CB", label: "Corriente de la batería", sonda: false },
    { id: "CE", label: "Conductividad eléctrica", sonda: true },
    { id: "CP", label: "Corriente del panel solar", sonda: false },
    { id: "HA", label: "Humedad ambiente", sonda: false },
    { id: "HS", label: "Humedad suelo", sonda: true },
    { id: "KI", label: "Potasio", sonda: true },
    { id: "NI", label: "Nitrógeno", sonda: true },
    { id: "PH", label: "PH", sonda: true },
    { id: "PI", label: "Fósforo", sonda: true },
    { id: "Rd", label: "Iluminancia", sonda: false },
    { id: "Rs", label: "Nivel de señal celular", sonda: false },
    { id: "TA", label: "Temperatura ambiente", sonda: false },
    { id: "TS", label: "Temperatura suelo", sonda: false },
    { id: "VB", label: "Voltaje de la batería", sonda: false },
    { id: "VP", label: "Voltaje del panel sonar", sonda: false }
]
