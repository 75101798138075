import React, { Component } from "react";
import Card from "react-bootstrap/Card";

import "bootstrap/dist/css/bootstrap.css";
import "../../styles/sensorsview.css";
import API from "./../api/api";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { withRouter } from "react-router-dom";
import editIcon from "../../img/edit_icon.png";

import SensorsUtils from "../utils/SensorsUtils";

class SensorCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sensor: this.props.values,
      showConfig: false,
      sensor_name: this.props.values.name,
      sensor_description: this.props.values.description,
      sensor_notifications: this.props.values.emails,
      sensor_crop: this.props.values.crop,
    };
    this.handleSaveSensor = this.handleSaveSensor.bind(this);
    this.handleShowConfig = this.handleShowConfig.bind(this);
    this.handleHideConfig = this.handleHideConfig.bind(this);
    this.updateSensor = this.updateSensor.bind(this);
    this.checkboxChange = this.checkboxChange.bind(this);
  }

  updateSensor(toGraph) {
    if (localStorage.getItem("actual-sensor"))
      localStorage.setItem("actual-sensor", "");
    //update checked value
    let sensorData = this.state.sensor;
    if (toGraph) sensorData.checked = true;
    else {
      sensorData.name = this.state.sensor_name;
      sensorData.crop = this.state.sensor_crop;
      sensorData.description = this.state.sensor_description;
      sensorData.emails = this.state.sensor_notifications;
      console.log(this.state.sensor_notifications);
    }
    console.log(sensorData);
    API.post("/sensors/update-sensor", sensorData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user-jwt"),
      },
    })
      .then((res) => {
        localStorage.setItem("actual-sensor", JSON.stringify(res.data));
        if (toGraph) this.props.history.push("/graphs");
        else this.setState({ sensor: res.data });
      })
      .catch((error) => {
          localStorage.removeItem("user-jwt");
          localStorage.removeItem("actual-sensor");
          this.props.history.push("/");
      });
  }

  onClick = () => {
    if (!this.state.checked) {
      this.updateSensor(true);
    } else {
      localStorage.setItem("actual-sensor", JSON.stringify(this.state.sensor));
      this.props.history.push("/graphs");
    }
  };

  handleSaveSensor = () => {
    this.updateSensor(false);
    this.setState({ showConfig: false });
  };

  handleShowConfig() {
    this.setState({ showConfig: true });
  }
  handleHideConfig() {
    this.setState({ showConfig: false });
  }

  checkboxChange() {
    this.setState({ sensor_notifications: !this.state.sensor_notifications });
  }

  render() {
    console.log(this.state.sensor);
    return (
      <div>
        <Card
          bg={
            this.state.sensor.checked
              ? "background-color: red"
              : "background-color: white"
          }
          border={!this.state.sensor.checked ? "danger" : "white"}
          text={!this.state.sensor.checked ? "black" : "black"}
          id="sensor_card"
          className="tarjeta_sensor"
        >
          <h6 className="CropType">{this.state.sensor.crop?.crop || this.state.sensor.description}</h6>
          <Card.Img
            variant="top"
            src={SensorsUtils.getBackgroundSensor(this.state.sensor.crop?.crop)}
            onClick={this.onClick}
          />
          <h6
            className="StateSensor"
            id={
              this.state.sensor.active
                ? "colorStateActive"
                : "colorStateInactive"
            }
          >
            {this.state.sensor.active ? "Activo" : "Inactivo"}
          </h6>
          <Card.Body onClick={this.onClick}>
            <div className="TitleCardContainer">
              <Card.Title>
                {this.state.sensor.name ? this.state.sensor.name : ""}
              </Card.Title>
            </div>
            <div className="cardDescription">
              {this.state.sensor.description
                ? this.state.sensor.description
                : ""}
            </div>
          </Card.Body>
          <Card.Link onClick={this.handleShowConfig} className="linkEditIcon">
            <img className="editIcon" src={editIcon} alt="Edit Icon" />
          </Card.Link>

          <Modal
            centered
            size="sm"
            className="ModalModifySensor"
            show={this.state.showConfig}
            onHide={this.handleHideConfig}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Modificar {this.state.sensor.name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Nombre del sensor</Form.Label>
                  <Form.Control
                    defaultValue={this.state.sensor.name}
                    name={"sensor_name"}
                    onChange = {(event) => this.setState({ sensor_name: event.target.value })}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Seleccione su cultivo</Form.Label>
                  <Form.Control
                    defaultValue={this.state.sensor.crop?.crop}
                    as="select"
                    name={"sensor_crop"}
                    onChange = {(event) => this.setState({ 
                                          sensor_crop:{
                                            crop: event.target.value,
                                            id: this.state.sensor.crop?.id // Reseteamos el ID ya que se cambia la selección del cultivo
                                          }
                                        })}
                  >
                    <option>Flores</option>
                    <option>Palma</option>
                    <option>Café</option>
                    <option>Aguacate</option>
                    <option>Tomate</option>
                    <option>Gulupa</option>
                    <option>Arándanos</option>
                    <option>Aromáticas</option>
                    <option>Otro</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Recibir notificaciones"
                    defaultChecked='this.state.sensor.emails' // TODO UPDATE EMAILS
                    onChange={this.checkboxChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="2"
                    defaultValue={this.state.sensor.crop?.crop || this.state.sensor.description} // TODO UPDATE SENSOR DESCRIPTION
                    name={"sensor_description"}
                    onChange = {(event) => this.setState({ sensor_description: event.target.value })} 
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={this.handleSaveSensor}
              >
                Guardar
              </Button>
            </Modal.Footer>
          </Modal>
        </Card>
      </div>
    );
  }
}
export default withRouter(SensorCard);
